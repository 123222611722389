import NextLink from 'next/link';
import { ReactNode } from 'react';
import { useRouter } from 'next/router';

export interface LayoutContentsProps {
  children: ReactNode | ReactNode[];
}

export function LayoutContents({ children }: LayoutContentsProps) {
  const router = useRouter();

  return (
    <div className="content bg-blue-50">
      {/* 2ND NAV */}
      <div className="bg-white border-gray-200">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          {/* <a
            href="#"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img src="/logo.svg" className="h-8" alt="Crowley Logo" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap">
              Fuels
            </span>
          </a> */}
          <button
            data-collapse-toggle="navbar-dropdown"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-dropdown"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className="hidden w-full md:block md:w-auto"
            id="navbar-dropdown"
          >
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white">
              {[
                { href: '/', label: 'Home' },
                { href: '/payment', label: 'Make a Payment' },
                { href: '/order-request', label: 'Request a Delivery' }
              ].map((item) => (
                <li key={item.href}>
                  <NextLink href={item.href}>
                    <a
                      className={`block py-2 px-3 rounded md:p-0 ${
                        router.pathname === item.href
                          ? 'text-blue-700'
                          : 'text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700'
                      }`}
                    >
                      {item.label}
                    </a>
                  </NextLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
}
