import '../styles/globals.css';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { OktaProvider } from '../components/OktaContext';
import { Layout } from '@org-crowley/enterprise-react-component-library';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { FeatureFlagProvider } from '../components/FeatureFlagContext';
import { LayoutContents } from 'components/LayoutContents';
import { useDataDog } from '../utils/useDataDog';
import Script from 'next/script';

function StarterApp({ Component, pageProps }: AppProps) {
  useDataDog();
  const [userName, setUserName] = useState('');
  const router = useRouter();

  return (
    <>
      <Head>
        <title>Crowley | Fuels</title>
      </Head>
      <Script src="https://cdnjs.cloudflare.com/ajax/libs/flowbite/2.3.0/flowbite.min.js" strategy="beforeInteractive" />
      <Script src="https://cdn.jsdelivr.net/npm/apexcharts"></Script>
      <OktaProvider setUserName={setUserName} userName={userName}>
        <FeatureFlagProvider>
          <Layout
            masthead={{
              title: 'Fuels',
              onLogoClick: () => {
                router.push('/');
              },
              name: userName
            }}
          >
            <LayoutContents>
              <Component {...pageProps}></Component>
            </LayoutContents>
          </Layout>
        </FeatureFlagProvider>
      </OktaProvider>
    </>
  );
}

export default StarterApp;
